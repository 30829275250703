<template>
  <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">

      <div class="d-flex align-center">
        <v-avatar tile size="25" class="me-3">
          <v-icon color="primary">mdi-card-account-phone-outline</v-icon>
        </v-avatar>
        <h2 class="mb-0">{{ $t("Contacts.add") }}</h2>
      </div>

      <v-btn
        outlined
        color="primary"
        class="text-capitalize font-600"
        :to="`/entities/${$route.params.entidadId}/account/contacts`"
      >
        {{ $t("Contacts.back") }}
      </v-btn>
    </div>
    <base-card>
      <v-form ref="contactForm">
        <div class="pa-4">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-subheader>{{ $t("Contacts.contactInformation") }}</v-subheader>
            </v-col>

            <v-col cols="12" md="12" lg="12" xl="12">
              <p class="text-14 mb-1">{{ $t("Contacts.nombreContacto") }}</p>
              <v-text-field
                v-model="contact.nombreContacto"
                :placeholder="$t('Contacts.nombreContactoEjemplo')"
                outlined
                dense
                :rules="[$sv.required]"
                hide-details
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.telefono") }}</p>
              <v-text-field
                v-model="contact.telefono"
                placeholder="9999999999"
                outlined
                dense
                :rules="[$sv.phone]"
                hide-details
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.email") }}</p>
              <v-text-field
                v-model="contact.email"
                placeholder="user@mail.com"
                outlined
                dense
                :rules="[$sv.email]"
                hide-details
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.fechaNacimiento") }}</p>

              <v-menu
                v-model="menu"
                ref="menu"
                :close-on-content-click="false"
                :return-value.sync="contact.fechaNacimiento"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="contact.fechaNacimiento"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    dense
                    outlined
                    v-on="on"
                    hide-details
                  />
                </template>
                <v-date-picker
                  v-model="contact.fechaNacimiento"
                  no-title
                  scrollable
                >
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(contact.fechaNacimiento)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.sexo") }}</p>
              <v-select
                v-model="contact.sexo"
                :items="[
                  {
                    label: 'Masculino',
                    value: 'H',
                  },
                  {
                    label: 'Femenino',
                    value: 'M',
                  },
                ]"
                outlined
                dense
                item-text="label"
                item-value="value"
                hide-details
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-subheader>{{ $t("Contacts.additionalInformation") }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.pasaporteId") }}</p>
              <v-text-field
                v-model="contact.pasaporteId"
                placeholder="9999999999"
                outlined
                dense
                :rules="[]"
                hide-details
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.razonSocial") }}</p>
              <v-text-field
                v-model="contact.razonSocial"
                placeholder="9999999999"
                outlined
                dense
                :rules="[]"
                hide-details
              />
            </v-col>

            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <p class="text-14 mb-1">{{ $t("Contacts.rfc") }}</p>
              <v-text-field
                v-model="contact.rfc"
                placeholder="9999999999"
                outlined
                dense
                :rules="[]"
                hide-details
              />
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                class="font-600 text-capitalize"
                @click="() => addContact()"
              >
                {{ $t('Contacts.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </base-card>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';

export default {
  data() {
    return {
      contact: {},
      menu: false,
      isEditing: false,
    };
  },
  mounted() {
    const cuentaContactoId = this.$route.params.cuentaContactoId;

    if (cuentaContactoId) {
      axios
        .get(`api/v1/private/contacts/${cuentaContactoId}`)
        .then((res) => {
          const contact = _.get(res, "data.data", {});
          this.contact = contact;
          this.isEditing = true;
        })
        .catch(() => {
          this.isEditing = false;

          const entidadId = this.$route.params.entidadId;

          this.$router.push(`/entities/${entidadId}/account/contacts`).catch(() => {});
        });
    }
  },
  methods: {
    addContact() {
      let req = null;

      let success = this.$refs.contactForm.validate();

      if (success) {
        if (this.isEditing) {
          // SE ACTUALIZA EL CONTACTO
          req = axios.put("api/v1/private/contacts", this.contact);
        } else {
          // SE CREA UN CONTACTO NUEVO
          req = axios.post("api/v1/private/contacts", this.contact);
        }

        req
          .then(() => {
            this.$router.push(`/entities/${entidadId}/account/contacts`).catch(() => {});
          })
          .catch(() => {
            // DOES NOTHING
          })
          .finally(() => {
            this.$emit('refresh');
          })
      }
    },
  },
};
</script>